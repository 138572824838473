export default {
  init() {
    // JavaScript to be fired on all pages

    $('.menu-item-has-children > a').addClass('test')
      .attr('data-toggle', 'dropdown')
      .attr('role', 'button')
      .attr('aria-haspopup', 'true')
      .attr('aria-expanded', 'false');

    $('.sub-menu').addClass('dropdown-menu');

    /**
     * WooCommerce Text Changes
     */
    function wooCommerceText() {
      if ($('.wc-bookings-date-picker-timezone').length) {
        $('.wc-bookings-date-picker-timezone').text('EST or EDT');
      }

      if ($('dt.variation-BookingDate').length) {
        $('dt.variation-BookingDate').text('Onsite Training Date: ');
      }

      if ($('dt.variation-BookingTime').length) {
        $('dt.variation-BookingTime').text('Onsite Training Time: ');
      }

      $(document.body).on('updated_checkout', function () {
        if ($('dt.variation-BookingDate').length) {
          $('dt.variation-BookingDate').text('Onsite Training Date: ');
        }

        if ($('dt.variation-BookingTime').length) {
          $('dt.variation-BookingTime').text('Onsite Training Time: ');
        }
      });

      if ($('.shop_table.my_account_bookings').length) {
        $('th.booking-start-date').text('Onsite Training Start Time: ');
        $('th.booking-end-date').text('Onsite Training End Time: ');
        $('.woocommerce-MyAccount-content h2').text('Upcoming Onsite Training Dates');
      }

      if ($('.wc-booking-summary-list').length) {
        var temptext = $('.wc-booking-summary-list').html();
        var newText = '<span>Your onsite training session is scheduled for:</span>' + temptext;
        $('.wc-booking-summary-list').html(newText);
      }
    }


    /**
     * Quiz - Changing the button value at the bottom of the quiz from 'Finish quiz' to 'Submit'
     */

    function wpProQuizButton() {
      $('.wpProQuiz_QuestionButton').prop('value', 'Submit');
    }

    // Fire all functions
    wooCommerceText();
    wpProQuizButton();


  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
